.page {
  &__panel {
    &_empty {
      :global(.p-panel-content) {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__empty_list__container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__button {
      align-self: center;
    }
  }

  &__card_list__container {
    justify-content: start;
  }
}

.page__card_list__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(Min(100%, 280px), 1fr));
  gap: 28px;
}

.card_list {
  &__card {
    background-color: var(--surface-ground);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 360px;

    :global(.p-card-content) {
      min-height: 100px;
    }

    :global(.p-card-title) {
      min-height: 75px;
      word-wrap: break-word;
    }

    :global(.p-card-body) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    :global(.p-card-footer) {
      margin-top: auto;
    }

    &__header__title {
      position: relative;
      height: 167px;

      @media (max-width: 988px) {
        height: 194px;
      }

      @media (max-width: 679px) {
        height: 264px;
      }

      background-color: var(--surface-ground);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &_tag {
        position: absolute;
        right: 8px;
        top: 10px;
      }
    }
  }
}
